@charset "UTF-8";
@font-face {
  font-family: "NHaasGroteskTXPro";
  font-display: fallback;
  font-weight: 400;
  src: url("./fonts/NeueHaasGroteskTextPro-55Roman.woff") format("woff"), url("./fonts/NeueHaasGroteskTextPro-55Roman.woff") format("woff");
}
@font-face {
  font-family: "NHaasGroteskTXPro";
  font-display: fallback;
  font-weight: 500;
  src: url("./fonts/NeueHaasGroteskTextPro-65Medium.woff") format("woff"), url("./fonts/NeueHaasGroteskTextPro-65Medium.woff") format("woff");
}
@font-face {
  font-family: "NHaasGroteskTXPro";
  font-display: fallback;
  font-weight: 700;
  src: url("./fonts/NeueHaasGroteskTextPro-75Bold.woff") format("woff"), url("./fonts/NeueHaasGroteskTextPro-75Bold.woff") format("woff");
}
/* Paddings */
* {
  margin: 0;
  padding: 0;
  font-weight: normal;
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  font-family: "NHaasGroteskTXPro";
}

a {
  text-decoration: none;
}

.rfb-showMessage {
  display: block;
  margin-top: 20px;
  font-weight: 500;
  font-size: 18px;
}

.success {
  color: green;
}

.swiper-container-home-slider .swiper-container{
  height: 100%;
}

html .gsap-timeline {
  opacity: 0;
}

.rfb-fti .react-parallax {
  height: 100%;
  width: 100%;
}

.rfb-fti .react-parallax img {
  height: calc(100% + 150px) !important;
  object-fit: cover;
}

.rfb-taib .react-parallax, 
.rfb-taib-slider .react-parallax, 
.rfb-news-single .react-parallax {
  top: 0;
  position: absolute !important;
  width: 100%;
  height: 100%;
}

html .react-parallax-bgimage {
  height: calc(100% + 150px) !important;
  object-fit: cover;
}

.rfb-height-vh {
  height: 100vh;
}
.three-holder {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
} 
html .rfb-taif-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  html .rfb-fti__picture {
    padding-top: 66.66%;
    width: 100%;
  }
  html .rfb-fti .react-parallax {
    position: absolute !important;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  html .rfb-taib .rfb-w-50.rfb-sm-w-100 {
    padding: 0 25px;
  }
}

.rfb-comp {
  display: inline-block;
  width: 100%;
  position: relative;
  vertical-align: top;
  overflow: hidden;
}
.rfb-comp--padding {
  padding: 120px 0;
}
.rfb-comp--dark {
  color: #fff;
  background-color: #000;
}
.rfb-comp--dark h1:not([class]), .rfb-comp--dark h2:not([class]), .rfb-comp--dark h3:not([class]), .rfb-comp--dark h4:not([class]), .rfb-comp--dark h5:not([class]), .rfb-comp--dark h6:not([class]), .rfb-comp--dark p:not([class]), .rfb-comp--dark span:not([class]), .rfb-comp--dark strong:not([class]) {
  color: #fff;
}
.rfb-comp--dark svg {
  fill: #fff;
}
.rfb-comp--dark input:not([type=submit]), .rfb-comp--dark textarea {
  color: #fff;
  background-color: #000;
}
.rfb-comp--padding-half {
  padding: 50px 0;
}
.rfb-comp--grey {
  background-color: #f3f3f3;
}
.rfb-comp--border-before {
  overflow: visible;
}
.rfb-comp--border-before:before {
  content: "";
  height: 116px;
  width: 2px;
  background-color: #FDE900;
  position: absolute;
  top: -58px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  will-change: trasnform;
}

.rfb-aspect-ratio-6x3 {
  width: 100%;
  padding-top: 50.13%;
  position: relative;
}
.rfb-aspect-ratio-6x3__img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rfb-aspect-ratio-16x9 {
  width: 100%;
  padding-top: 56.52%;
  position: relative;
}
.rfb-aspect-ratio-16x9__img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rfb-aspect-ratio-1x1 {
  width: 100%;
  padding-top: 100%;
  position: relative;
}
.rfb-aspect-ratio-1x1__img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rfb-d-none {
  display: none;
}
.rfb-d-block {
  display: block;
}
.rfb-d-grid {
  display: grid;
}
.rfb-d-inline-block {
  display: inline-block;
}

.rfb-parag {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 2.1;
}
@media (min-width: 576px) {
  .rfb-parag {
    font-size: calc(0.9615384615vw + 12.4615384615px);
  }
}
@media (min-width: 992px) {
  .rfb-parag {
    font-size: 22px;
  }
}
.rfb-parag strong {
  font-size: 16px;
  line-height: 1.89;
}
@media (min-width: 576px) {
  .rfb-parag strong {
    font-size: calc(0.4807692308vw + 13.2307692308px);
  }
}
@media (min-width: 992px) {
  .rfb-parag strong {
    font-size: 18px;
  }
}
.rfb-parag--underline {
  text-decoration: underline;
}
.rfb-parag--link-black a {
  color: rgba(0, 0, 0, 0.84);
  text-decoration: underline;
}
.rfb-parag--link-white a {
  color: #fff;
  text-decoration: underline;
}
.rfb-parag--border {
  padding-left: 8px;
  border-left: 2px solid #2F2F2F;
}
.rfb-parag-links a {
  color: inherit;
  text-decoration: underline;
}
.rfb-parag-small {
  font-size: 15px;
  line-height: 1.73;
}

.rfb-ls-16-negative {
  letter-spacing: -0.16px;
}

.rfb-m-auto {
  margin: 0 auto;
}

.rfb-my-auto {
  margin: auto 0;
}

.rfb-mt-0 {
  margin-top: 0;
}
.rfb-mt-12 {
  margin-top: 12px;
}
.rfb-mt-16 {
  margin-top: 16px;
}
.rfb-mt-32 {
  margin-top: 32px;
}
.rfb-mt-40 {
  margin-top: 40px;
}
.rfb-mt-48 {
  margin-top: 48px;
}
.rfb-mt-64 {
  margin-top: 64px;
}
.rfb-mt-72 {
  margin-top: 72px;
}
.rfb-mt-80 {
  margin-top: 44px;
}
@media (min-width: 576px) {
  .rfb-mt-80 {
    margin-top: calc(6.25vw + 8px);
  }
}
@media (min-width: 768px) {
  .rfb-mt-80 {
    margin-top: calc(10.7142857143vw - 26.2857142857px);
  }
}
@media (min-width: 992px) {
  .rfb-mt-80 {
    margin-top: 80px;
  }
}
.rfb-mt-100 {
  margin-top: 64px;
}
@media (min-width: 576px) {
  .rfb-mt-100 {
    margin-top: calc(8.3333333333vw + 16px);
  }
}
@media (min-width: 768px) {
  .rfb-mt-100 {
    margin-top: calc(8.9285714286vw + 11.4285714286px);
  }
}
@media (min-width: 992px) {
  .rfb-mt-100 {
    margin-top: 100px;
  }
}
.rfb-mt-20-negative {
  margin-top: -20px;
}
.rfb-mt-30-negative {
  margin-top: -30px;
}
.rfb-mt-40-negative {
  margin-top: -40px;
}
.rfb-mt-60-negative {
  margin-top: -60px;
  bottom: -45px;
}
@media (min-width: 576px) {
  .rfb-mt-60-negative {
    bottom: calc(-7.8125vw + 0px);
  }
}
@media (min-width: 768px) {
  .rfb-mt-60-negative {
    bottom: -60px;
  }
}
.rfb-mr-auto {
  margin-right: auto;
}
.rfb-mr-8 {
  margin-right: 8px;
}
.rfb-mr-12 {
  margin-right: 12px;
}
.rfb-mr-16 {
  margin-right: 16px;
}
.rfb-mr-24 {
  margin-right: 24px;
}
.rfb-mr-32 {
  margin-right: 32px;
}
.rfb-mb-0 {
  margin-bottom: 0;
}
.rfb-mb-4 {
  margin-bottom: 4px;
}
.rfb-mb-8 {
  margin-bottom: 8px;
}
.rfb-mb-12 {
  margin-bottom: 12px;
}
.rfb-mb-16 {
  margin-bottom: 16px;
}
.rfb-mb-18 {
  margin-bottom: 18px;
}
.rfb-mb-20 {
  margin-bottom: 20px;
}
.rfb-mb-24 {
  margin-bottom: 24px;
}
.rfb-mb-28 {
  margin-bottom: 28px;
}
.rfb-mb-32 {
  margin-bottom: 32px;
}
.rfb-mb-40 {
  margin-bottom: 40px;
}
.rfb-mb-48 {
  margin-bottom: 48px;
}
.rfb-mb-56 {
  margin-bottom: 40px;
}
@media (min-width: 576px) {
  .rfb-mb-56 {
    margin-bottom: calc(3.8461538462vw + 17.8461538462px);
  }
}
@media (min-width: 992px) {
  .rfb-mb-56 {
    margin-bottom: 56px;
  }
}
.rfb-mb-64 {
  margin-bottom: 64px;
}
.rfb-mb-72 {
  margin-bottom: 40px;
}
@media (min-width: 576px) {
  .rfb-mb-72 {
    margin-bottom: calc(6.25vw + 4px);
  }
}
@media (min-width: 768px) {
  .rfb-mb-72 {
    margin-bottom: calc(8.9285714286vw - 16.5714285714px);
  }
}
@media (min-width: 992px) {
  .rfb-mb-72 {
    margin-bottom: 72px;
  }
}
.rfb-mb-80 {
  margin-bottom: 52px;
}
@media (min-width: 576px) {
  .rfb-mb-80 {
    margin-bottom: calc(6.3492063492vw + 15.4285714286px);
  }
}
@media (min-width: 765px) {
  .rfb-mb-80 {
    margin-bottom: calc(7.0484581498vw + 10.0792951542px);
  }
}
@media (min-width: 992px) {
  .rfb-mb-80 {
    margin-bottom: 80px;
  }
}
.rfb-mb-90 {
  margin-bottom: 90px;
}
.rfb-mb-100 {
  margin-bottom: 48px;
}
@media (min-width: 576px) {
  .rfb-mb-100 {
    margin-bottom: calc(16.9312169312vw - 49.5238095238px);
  }
}
@media (min-width: 765px) {
  .rfb-mb-100 {
    margin-bottom: calc(8.8105726872vw + 12.5991189427px);
  }
}
@media (min-width: 992px) {
  .rfb-mb-100 {
    margin-bottom: 100px;
  }
}
.rfb-mb-140 {
  margin-bottom: 80px;
}
@media (min-width: 576px) {
  .rfb-mb-140 {
    margin-bottom: calc(16.9312169312vw - 17.5238095238px);
  }
}
@media (min-width: 765px) {
  .rfb-mb-140 {
    margin-bottom: calc(12.3348017621vw + 17.6387665198px);
  }
}
@media (min-width: 992px) {
  .rfb-mb-140 {
    margin-bottom: 140px;
  }
}
.rfb-ml-auto {
  margin-left: auto;
}
.rfb-ml-8 {
  margin-left: 8px;
}
.rfb-ml-16 {
  margin-left: 16px;
}
.rfb-ml-24 {
  margin-left: 24px;
}
.rfb-ml-32 {
  margin-left: 32px;
}

.rfb-p-14 {
  padding: 14px 0;
}
.rfb-p-16 {
  padding: 16px 0;
}
.rfb-p-40 {
  padding: 40px 0;
}
.rfb-p-50 {
  padding: 50px;
}
.rfb-pt-0 {
  padding-top: 0;
}
.rfb-pt-24 {
  padding-top: 24px;
}
.rfb-pt-44 {
  padding-top: 44px;
}
.rfb-pt-72 {
  padding-top: 72px;
}
.rfb-pt-85 {
  padding-top: 85px;
}
.rfb-pr-10 {
  padding-right: 10px;
}
.rfb-pr-14 {
  padding-right: 14px;
}
.rfb-pr-16 {
  padding-right: 16px;
}
.rfb-pr-32 {
  padding-right: 32px;
}
.rfb-pr-40 {
  padding-right: 40px;
}
.rfb-pr-30 {
  padding-right: 0px;
}
@media (min-width: 576px) {
  .rfb-pr-30 {
    padding-right: calc(7.2115384615vw - 41.5384615385px);
  }
}
@media (min-width: 992px) {
  .rfb-pr-30 {
    padding-right: 30px;
  }
}
.rfb-pr-42 {
  padding-right: 42px;
}
.rfb-pr-50 {
  padding-right: 50px;
}
.rfb-pr-60 {
  padding-right: 60px;
}
.rfb-pr-100 {
  padding-right: 100px;
}
.rfb-pr-120 {
  padding-right: 0px;
}
@media (min-width: 576px) {
  .rfb-pr-120 {
    padding-right: calc(28.8461538462vw - 166.1538461538px);
  }
}
@media (min-width: 992px) {
  .rfb-pr-120 {
    padding-right: 120px;
  }
}
.rfb-pr-250 {
  padding-right: 0px;
}
@media (min-width: 576px) {
  .rfb-pr-250 {
    padding-right: calc(60.0961538462vw - 346.1538461538px);
  }
}
@media (min-width: 992px) {
  .rfb-pr-250 {
    padding-right: 250px;
  }
}
.rfb-pb-8 {
  padding-bottom: 8px;
}
.rfb-pb-16 {
  padding-bottom: 16px;
}
.rfb-pb-24 {
  padding-bottom: 24px;
}
.rfb-pb-32 {
  padding-bottom: 32px;
}
.rfb-pb-40 {
  padding-bottom: 40px;
}
.rfb-pb-44 {
  padding-bottom: 44px;
}
.rfb-pb-48 {
  padding-bottom: 48px;
}
.rfb-pb-80 {
  padding-bottom: 80px;
}
.rfb-pb-100 {
  padding-bottom: 100px;
}
.rfb-pb-135 {
  padding-bottom: 135px;
}
.rfb-pb-190 {
  padding-bottom: 190px;
}
.rfb-pl-8 {
  padding-left: 8px;
}
.rfb-pl-12 {
  padding-left: 12px;
}
.rfb-pl-16 {
  padding-left: 16px;
}
.rfb-pl-24 {
  padding-left: 24px;
}
.rfb-pl-30 {
  padding-left: 30px;
}
.rfb-pl-40 {
  padding-left: 40px;
}
.rfb-pl-42 {
  padding-left: 42px;
}

.rfb-t-0 {
  top: 0;
}
.rfb-t-4 {
  top: 4px;
}

.rfb-r-0 {
  right: 0;
}
.rfb-r-15 {
  right: 15px;
}

.rfb-b-0 {
  bottom: 0px;
}

.rfb-l-15 {
  left: 15px;
}

.rfb-object-contain {
  object-fit: contain;
}
.rfb-object-cover {
  object-fit: cover;
}
.rfb-object-pos-center {
  object-position: center;
}
.rfb-object-pos-left {
  object-position: left;
}

.rfb-max-w-30 {
  max-width: 30%;
}
.rfb-max-w-40 {
  max-width: 40%;
}
.rfb-max-w-50 {
  max-width: 50%;
}
.rfb-max-w-60 {
  max-width: 60%;
}
.rfb-max-w-65 {
  max-width: 65%;
}
.rfb-max-w-70 {
  max-width: 70%;
}
.rfb-max-w-80 {
  max-width: 80%;
}
.rfb-max-w-100 {
  max-width: 100%;
}

.rfb-w-45 {
  width: 45%;
}
.rfb-w-50 {
  width: 50%;
}
.rfb-w-55 {
  width: 55%;
}
.rfb-w-60 {
  width: 60%;
}
.rfb-w-70 {
  width: 70%;
}
.rfb-w-80 {
  width: 80%;
}
.rfb-w-95 {
  width: 95%;
}
.rfb-w-100 {
  width: 100%;
}

.rfb-min-h-100 {
  min-height: 100%;
}
.rfb-min-h-90 {
  min-height: 90px;
}

.rfb-max-h-100 {
  max-height: 100%;
}

.rfb-window-height {
  height: 100%;
}

.rfb-relative {
  position: relative;
}

.rfb-absolute {
  position: absolute;
}

.rfb-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  will-change: trasnform;
}

.rfb-flex {
  display: flex;
}
.rfb-flex--items-center {
  align-items: center;
}
.rfb-flex--items-start {
  align-items: flex-start;
}
.rfb-flex--items-end {
  align-items: flex-end;
}
.rfb-flex--items-baseline {
  align-items: baseline;
}
.rfb-flex--justify-center {
  justify-content: center;
}
.rfb-flex--justify-end {
  justify-content: flex-end;
}
.rfb-flex--space-between {
  justify-content: space-between;
}
.rfb-flex--wrap {
  flex-wrap: wrap;
}
.rfb-flex--direction-column {
  flex-direction: column;
}
.rfb-flex--none {
  flex: none;
}
.rfb-flex--inline {
  display: inline-flex;
}
.rfb-flex--content-center {
  align-content: center;
}

.rfb-title-quote {
  font-size: 16px;
  line-height: 1.33;
  letter-spacing: 5px;
  text-transform: uppercase;
}
@media (min-width: 576px) {
  .rfb-title-quote {
    font-size: calc(0.4807692308vw + 13.2307692308px);
  }
}
@media (min-width: 992px) {
  .rfb-title-quote {
    font-size: 18px;
  }
}
.rfb-title-primary {
  font-size: 32px;
  line-height: 1.37;
}
@media (min-width: 576px) {
  .rfb-title-primary {
    font-size: calc(4.1666666667vw + 8px);
  }
}
@media (min-width: 768px) {
  .rfb-title-primary {
    font-size: calc(4.9107142857vw + 2.2857142857px);
  }
}
@media (min-width: 992px) {
  .rfb-title-primary {
    font-size: 51px;
  }
}
.rfb-title-secondary {
  font-size: 26px;
  line-height: 1.5;
}
@media (min-width: 576px) {
  .rfb-title-secondary {
    font-size: calc(5.2083333333vw - 4px);
  }
}
@media (min-width: 768px) {
  .rfb-title-secondary {
    font-size: calc(2.6785714286vw + 15.4285714286px);
  }
}
@media (min-width: 992px) {
  .rfb-title-secondary {
    font-size: 42px;
  }
}
.rfb-title-third {
  font-size: 24px;
  line-height: 1.73;
}
@media (min-width: 576px) {
  .rfb-title-third {
    font-size: calc(3.125vw + 6px);
  }
}
@media (min-width: 768px) {
  .rfb-title-third {
    font-size: calc(3.5714285714vw + 2.5714285714px);
  }
}
@media (min-width: 992px) {
  .rfb-title-third {
    font-size: 38px;
  }
}
.rfb-title-fourth {
  font-size: 19px;
  line-height: 1.33;
}
@media (min-width: 576px) {
  .rfb-title-fourth {
    font-size: calc(1.2019230769vw + 12.0769230769px);
  }
}
@media (min-width: 992px) {
  .rfb-title-fourth {
    font-size: 24px;
  }
}
.rfb-title-border {
  padding-left: 16px;
}
@media (min-width: 576px) {
  .rfb-title-border {
    padding-left: calc(4.3269230769vw - 8.9230769231px);
  }
}
@media (min-width: 992px) {
  .rfb-title-border {
    padding-left: 34px;
  }
}
.rfb-title-border:before {
  content: "";
  position: absolute;
  width: 6px;
  width: 4px;
  height: 100%;
  left: 0;
  bottom: 0;
  background-color: #FFF400;
}
@media (min-width: 576px) {
  .rfb-title-border:before {
    width: calc(0.4807692308vw + 1.2307692308px);
  }
}
@media (min-width: 992px) {
  .rfb-title-border:before {
    width: 6px;
  }
}

.rfb-button {
  display: inline-block;
  font-size: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 40px;
  padding-right: 40px;
  line-height: 2.25;
  outline: none;
  border-radius: 0;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (min-width: 576px) {
  .rfb-button {
    font-size: calc(0.4807692308vw + 15.2307692308px);
  }
}
@media (min-width: 992px) {
  .rfb-button {
    font-size: 20px;
  }
}
@media (min-width: 576px) {
  .rfb-button {
    padding-top: calc(0.9615384615vw + 2.4615384615px);
  }
}
@media (min-width: 992px) {
  .rfb-button {
    padding-top: 12px;
  }
}
@media (min-width: 576px) {
  .rfb-button {
    padding-bottom: calc(0.9615384615vw + 2.4615384615px);
  }
}
@media (min-width: 992px) {
  .rfb-button {
    padding-bottom: 12px;
  }
}
@media (min-width: 576px) {
  .rfb-button {
    padding-left: calc(4.8076923077vw + 12.3076923077px);
  }
}
@media (min-width: 992px) {
  .rfb-button {
    padding-left: 60px;
  }
}
@media (min-width: 576px) {
  .rfb-button {
    padding-right: calc(4.8076923077vw + 12.3076923077px);
  }
}
@media (min-width: 992px) {
  .rfb-button {
    padding-right: 60px;
  }
}
.rfb-button svg path {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.rfb-button--primary {
  color: #000;
  border: 1px solid #FDE900;
  background-color: #FDE900;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.rfb-button--primary:hover {
  color: #FDE900;
  background-color: #000;
}
.rfb-button--primary:hover svg path {
  fill: #fff;
}
.rfb-button--secondary {
  color: #fff;
  background-color: #121212;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.rfb-button--secondary:hover {
  color: #000;
  background-color: #FDE900;
}
.rfb-button--border {
  padding: 12px 32px;
  border: 1px solid #000;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.rfb-button--border:hover {
  color: #fff;
  background-color: #000;
}
.rfb-button--border-primary {
  border-color: #FDE900;
}
.rfb-button--border-white {
  border-color: #fff;
  color: #fff;
}
.rfb-button--border-white:hover {
  color: #000;
  background-color: #fff;
}
.rfb-button--extra-large {
  padding-left: 86px;
  padding-right: 86px;
}
@media (min-width: 576px) {
  .rfb-button--extra-large {
    padding-left: calc(11.4583333333vw + 20px);
  }
}
@media (min-width: 768px) {
  .rfb-button--extra-large {
    padding-left: calc(12.0535714286vw + 15.4285714286px);
  }
}
@media (min-width: 992px) {
  .rfb-button--extra-large {
    padding-left: 135px;
  }
}
@media (min-width: 576px) {
  .rfb-button--extra-large {
    padding-right: calc(11.4583333333vw + 20px);
  }
}
@media (min-width: 768px) {
  .rfb-button--extra-large {
    padding-right: calc(12.0535714286vw + 15.4285714286px);
  }
}
@media (min-width: 992px) {
  .rfb-button--extra-large {
    padding-right: 135px;
  }
}
.rfb-button--large {
  padding-left: 32px;
  padding-right: 32px;
}
@media (min-width: 576px) {
  .rfb-button--large {
    padding-left: calc(4.1666666667vw + 8px);
  }
}
@media (min-width: 768px) {
  .rfb-button--large {
    padding-left: calc(15.625vw - 80px);
  }
}
@media (min-width: 992px) {
  .rfb-button--large {
    padding-left: 75px;
  }
}
@media (min-width: 576px) {
  .rfb-button--large {
    padding-right: calc(4.1666666667vw + 8px);
  }
}
@media (min-width: 768px) {
  .rfb-button--large {
    padding-right: calc(15.625vw - 80px);
  }
}
@media (min-width: 992px) {
  .rfb-button--large {
    padding-right: 75px;
  }
}
.rfb-button--small {
  padding-left: 16px;
  padding-right: 16px;
}

.rfb-link {
  font-size: 16px;
  line-height: 1.75;
}
.rfb-link--icon svg {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  will-change: trasnform;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.rfb-link--icon:hover svg {
  -webkit-transform: translateX(6px);
  -moz-transform: translateX(6px);
  -ms-transform: translateX(6px);
  -o-transform: translateX(6px);
  transform: translateX(6px);
  will-change: trasnform;
}
.rfb-link--border-middle span:before {
  content: "";
  width: 22px;
  height: 1px;
  display: inline-block;
  margin: 0 12px;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-link--border-middle:hover span:before {
  width: 28px;
}

.rfb-overflow-y-auto {
  overflow-y: auto;
}

.rfb-text-left {
  text-align: left;
}
.rfb-text-center {
  text-align: center;
}
.rfb-text-right {
  text-align: right;
}
.rfb-text-uppercase {
  text-transform: uppercase;
}
.rfb-text-capitalize {
  text-transform: capitalize;
}

.rfb-zindex-1 {
  z-index: 1;
}
.rfb-zindex-2 {
  z-index: 2;
}

.rfb-container {
  width: 100%;
  max-width: 1160px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;
}

.rfb-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -14px;
  margin-left: -14px;
}

[class*=rfb-col-] {
  padding-left: 14px;
  padding-right: 14px;
}

.rfb-img-100 {
  width: 100%;
}
.rfb-img-h-100 {
  height: 100%;
}

.rfb-color-primary {
  color: #FDE900;
}
.rfb-color-secondary {
  color: #FFF400;
}
.rfb-color-third {
  color: #2F2F2F;
}
.rfb-color-white, .rfb-color-white a {
  color: #fff;
}
.rfb-color-white:before {
  background-color: #fff;
}
.rfb-color-white--hover {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-color-white--hover:hover {
  color: rgba(255, 255, 255, 0.5);
}
.rfb-color-black {
  color: #000;
}
.rfb-color-black:before {
  background-color: #000;
}

.rfb-bg-white {
  background-color: #fff;
}
.rfb-bg--grey {
  background-color: #f3f3f3;
}
.rfb-bg--black {
  background-color: #000;
}

.rfb-font-primary {
  font-family: "NHaasGroteskTXPro";
}
.rfb-font-primary-regular, .rfb-font-primary-regular p:not([class]), .rfb-font-primary-regular span:not([class]) {
  font-weight: 400;
}
.rfb-font-primary-bold, .rfb-font-primary-bold p:not([class]), .rfb-font-primary-bold span:not([class]) {
  font-weight: 700;
}
.rfb-font-primary-medium, .rfb-font-primary-medium p:not([class]), .rfb-font-primary-medium span:not([class]) {
  font-weight: 500;
}

.rfb-tabs__item {
  font-size: 18px;
  line-height: 1.875;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (min-width: 576px) {
  .rfb-tabs__item {
    font-size: calc(3.125vw + 0px);
  }
}
@media (min-width: 768px) {
  .rfb-tabs__item {
    font-size: 24px;
  }
}
.rfb-tabs__item:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 1px;
  width: 100%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  will-change: trasnform;
  transition: 0.1s;
}
@media (min-width: 576px) {
  .rfb-tabs__item:after {
    bottom: calc(-6.25vw + 36px);
  }
}
@media (min-width: 768px) {
  .rfb-tabs__item:after {
    bottom: -12px;
  }
}
.rfb-tabs__item--light {
  color: rgba(255, 255, 255, 0.5);
}
.rfb-tabs__item--light:hover {
  color: #fff;
}
.rfb-tabs__item--light:after {
  background-color: rgba(255, 255, 255, 0.5);
}
.rfb-tabs__item--dark {
  color: rgba(0, 0, 0, 0.3);
}
.rfb-tabs__item--dark:hover {
  color: #000;
}
.rfb-tabs__item--dark:after {
  background-color: rgba(0, 0, 0, 0.3);
}
.rfb-tabs__checkbox {
  display: none;
}
.rfb-tabs__checkbox#tab-1:checked ~ .rfb-tabs__content .rfb-tabs__content__tab:nth-of-type(1) {
  display: flex;
}
.rfb-tabs__checkbox#tab-1:checked ~ .rfb-tabs__buttons .rfb-tabs__item--light:nth-child(1) {
  color: #fff;
}
.rfb-tabs__checkbox#tab-1:checked ~ .rfb-tabs__buttons .rfb-tabs__item--dark:nth-child(1) {
  color: #000;
}
.rfb-tabs__checkbox#tab-1:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(1):after {
  height: 3px;
  background-color: #FDE900;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-tabs__checkbox#tab-1:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(1).rfb-tabs__item--light:after {
  bottom: -4px;
}
@media (min-width: 576px) {
  .rfb-tabs__checkbox#tab-1:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(1).rfb-tabs__item--light:after {
    bottom: calc(-5.2083333333vw + 26px);
  }
}
@media (min-width: 768px) {
  .rfb-tabs__checkbox#tab-1:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(1).rfb-tabs__item--light:after {
    bottom: -14px;
  }
}
.rfb-tabs__checkbox#tab-1:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(1).rfb-tabs__item--dark:after {
  background-color: #000;
}
.rfb-tabs__checkbox#tab-2:checked ~ .rfb-tabs__content .rfb-tabs__content__tab:nth-of-type(2) {
  display: flex;
}
.rfb-tabs__checkbox#tab-2:checked ~ .rfb-tabs__buttons .rfb-tabs__item--light:nth-child(2) {
  color: #fff;
}
.rfb-tabs__checkbox#tab-2:checked ~ .rfb-tabs__buttons .rfb-tabs__item--dark:nth-child(2) {
  color: #000;
}
.rfb-tabs__checkbox#tab-2:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(2):after {
  height: 3px;
  background-color: #FDE900;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-tabs__checkbox#tab-2:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(2).rfb-tabs__item--light:after {
  bottom: -4px;
}
@media (min-width: 576px) {
  .rfb-tabs__checkbox#tab-2:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(2).rfb-tabs__item--light:after {
    bottom: calc(-5.2083333333vw + 26px);
  }
}
@media (min-width: 768px) {
  .rfb-tabs__checkbox#tab-2:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(2).rfb-tabs__item--light:after {
    bottom: -14px;
  }
}
.rfb-tabs__checkbox#tab-2:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(2).rfb-tabs__item--dark:after {
  background-color: #000;
}
.rfb-tabs__checkbox#tab-3:checked ~ .rfb-tabs__content .rfb-tabs__content__tab:nth-of-type(3) {
  display: flex;
}
.rfb-tabs__checkbox#tab-3:checked ~ .rfb-tabs__buttons .rfb-tabs__item--light:nth-child(3) {
  color: #fff;
}
.rfb-tabs__checkbox#tab-3:checked ~ .rfb-tabs__buttons .rfb-tabs__item--dark:nth-child(3) {
  color: #000;
}
.rfb-tabs__checkbox#tab-3:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(3):after {
  height: 3px;
  background-color: #FDE900;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-tabs__checkbox#tab-3:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(3).rfb-tabs__item--light:after {
  bottom: -4px;
}
@media (min-width: 576px) {
  .rfb-tabs__checkbox#tab-3:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(3).rfb-tabs__item--light:after {
    bottom: calc(-5.2083333333vw + 26px);
  }
}
@media (min-width: 768px) {
  .rfb-tabs__checkbox#tab-3:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(3).rfb-tabs__item--light:after {
    bottom: -14px;
  }
}
.rfb-tabs__checkbox#tab-3:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(3).rfb-tabs__item--dark:after {
  background-color: #000;
}
.rfb-tabs__checkbox#tab-4:checked ~ .rfb-tabs__content .rfb-tabs__content__tab:nth-of-type(4) {
  display: flex;
}
.rfb-tabs__checkbox#tab-4:checked ~ .rfb-tabs__buttons .rfb-tabs__item--light:nth-child(4) {
  color: #fff;
}
.rfb-tabs__checkbox#tab-4:checked ~ .rfb-tabs__buttons .rfb-tabs__item--dark:nth-child(4) {
  color: #000;
}
.rfb-tabs__checkbox#tab-4:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(4):after {
  height: 3px;
  background-color: #FDE900;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-tabs__checkbox#tab-4:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(4).rfb-tabs__item--light:after {
  bottom: -4px;
}
@media (min-width: 576px) {
  .rfb-tabs__checkbox#tab-4:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(4).rfb-tabs__item--light:after {
    bottom: calc(-5.2083333333vw + 26px);
  }
}
@media (min-width: 768px) {
  .rfb-tabs__checkbox#tab-4:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(4).rfb-tabs__item--light:after {
    bottom: -14px;
  }
}
.rfb-tabs__checkbox#tab-4:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(4).rfb-tabs__item--dark:after {
  background-color: #000;
}
.rfb-tabs__checkbox#tab-5:checked ~ .rfb-tabs__content .rfb-tabs__content__tab:nth-of-type(5) {
  display: flex;
}
.rfb-tabs__checkbox#tab-5:checked ~ .rfb-tabs__buttons .rfb-tabs__item--light:nth-child(5) {
  color: #fff;
}
.rfb-tabs__checkbox#tab-5:checked ~ .rfb-tabs__buttons .rfb-tabs__item--dark:nth-child(5) {
  color: #000;
}
.rfb-tabs__checkbox#tab-5:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(5):after {
  height: 3px;
  background-color: #FDE900;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-tabs__checkbox#tab-5:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(5).rfb-tabs__item--light:after {
  bottom: -4px;
}
@media (min-width: 576px) {
  .rfb-tabs__checkbox#tab-5:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(5).rfb-tabs__item--light:after {
    bottom: calc(-5.2083333333vw + 26px);
  }
}
@media (min-width: 768px) {
  .rfb-tabs__checkbox#tab-5:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(5).rfb-tabs__item--light:after {
    bottom: -14px;
  }
}
.rfb-tabs__checkbox#tab-5:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(5).rfb-tabs__item--dark:after {
  background-color: #000;
}
.rfb-tabs__checkbox#tab-6:checked ~ .rfb-tabs__content .rfb-tabs__content__tab:nth-of-type(6) {
  display: flex;
}
.rfb-tabs__checkbox#tab-6:checked ~ .rfb-tabs__buttons .rfb-tabs__item--light:nth-child(6) {
  color: #fff;
}
.rfb-tabs__checkbox#tab-6:checked ~ .rfb-tabs__buttons .rfb-tabs__item--dark:nth-child(6) {
  color: #000;
}
.rfb-tabs__checkbox#tab-6:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(6):after {
  height: 3px;
  background-color: #FDE900;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-tabs__checkbox#tab-6:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(6).rfb-tabs__item--light:after {
  bottom: -4px;
}
@media (min-width: 576px) {
  .rfb-tabs__checkbox#tab-6:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(6).rfb-tabs__item--light:after {
    bottom: calc(-5.2083333333vw + 26px);
  }
}
@media (min-width: 768px) {
  .rfb-tabs__checkbox#tab-6:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(6).rfb-tabs__item--light:after {
    bottom: -14px;
  }
}
.rfb-tabs__checkbox#tab-6:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(6).rfb-tabs__item--dark:after {
  background-color: #000;
}
.rfb-tabs__checkbox#tab-7:checked ~ .rfb-tabs__content .rfb-tabs__content__tab:nth-of-type(7) {
  display: flex;
}
.rfb-tabs__checkbox#tab-7:checked ~ .rfb-tabs__buttons .rfb-tabs__item--light:nth-child(7) {
  color: #fff;
}
.rfb-tabs__checkbox#tab-7:checked ~ .rfb-tabs__buttons .rfb-tabs__item--dark:nth-child(7) {
  color: #000;
}
.rfb-tabs__checkbox#tab-7:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(7):after {
  height: 3px;
  background-color: #FDE900;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-tabs__checkbox#tab-7:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(7).rfb-tabs__item--light:after {
  bottom: -4px;
}
@media (min-width: 576px) {
  .rfb-tabs__checkbox#tab-7:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(7).rfb-tabs__item--light:after {
    bottom: calc(-5.2083333333vw + 26px);
  }
}
@media (min-width: 768px) {
  .rfb-tabs__checkbox#tab-7:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(7).rfb-tabs__item--light:after {
    bottom: -14px;
  }
}
.rfb-tabs__checkbox#tab-7:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(7).rfb-tabs__item--dark:after {
  background-color: #000;
}
.rfb-tabs__checkbox#tab-8:checked ~ .rfb-tabs__content .rfb-tabs__content__tab:nth-of-type(8) {
  display: flex;
}
.rfb-tabs__checkbox#tab-8:checked ~ .rfb-tabs__buttons .rfb-tabs__item--light:nth-child(8) {
  color: #fff;
}
.rfb-tabs__checkbox#tab-8:checked ~ .rfb-tabs__buttons .rfb-tabs__item--dark:nth-child(8) {
  color: #000;
}
.rfb-tabs__checkbox#tab-8:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(8):after {
  height: 3px;
  background-color: #FDE900;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-tabs__checkbox#tab-8:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(8).rfb-tabs__item--light:after {
  bottom: -4px;
}
@media (min-width: 576px) {
  .rfb-tabs__checkbox#tab-8:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(8).rfb-tabs__item--light:after {
    bottom: calc(-5.2083333333vw + 26px);
  }
}
@media (min-width: 768px) {
  .rfb-tabs__checkbox#tab-8:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(8).rfb-tabs__item--light:after {
    bottom: -14px;
  }
}
.rfb-tabs__checkbox#tab-8:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(8).rfb-tabs__item--dark:after {
  background-color: #000;
}
.rfb-tabs__checkbox#tab-9:checked ~ .rfb-tabs__content .rfb-tabs__content__tab:nth-of-type(9) {
  display: flex;
}
.rfb-tabs__checkbox#tab-9:checked ~ .rfb-tabs__buttons .rfb-tabs__item--light:nth-child(9) {
  color: #fff;
}
.rfb-tabs__checkbox#tab-9:checked ~ .rfb-tabs__buttons .rfb-tabs__item--dark:nth-child(9) {
  color: #000;
}
.rfb-tabs__checkbox#tab-9:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(9):after {
  height: 3px;
  background-color: #FDE900;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-tabs__checkbox#tab-9:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(9).rfb-tabs__item--light:after {
  bottom: -4px;
}
@media (min-width: 576px) {
  .rfb-tabs__checkbox#tab-9:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(9).rfb-tabs__item--light:after {
    bottom: calc(-5.2083333333vw + 26px);
  }
}
@media (min-width: 768px) {
  .rfb-tabs__checkbox#tab-9:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(9).rfb-tabs__item--light:after {
    bottom: -14px;
  }
}
.rfb-tabs__checkbox#tab-9:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(9).rfb-tabs__item--dark:after {
  background-color: #000;
}
.rfb-tabs__checkbox#tab-10:checked ~ .rfb-tabs__content .rfb-tabs__content__tab:nth-of-type(10) {
  display: flex;
}
.rfb-tabs__checkbox#tab-10:checked ~ .rfb-tabs__buttons .rfb-tabs__item--light:nth-child(10) {
  color: #fff;
}
.rfb-tabs__checkbox#tab-10:checked ~ .rfb-tabs__buttons .rfb-tabs__item--dark:nth-child(10) {
  color: #000;
}
.rfb-tabs__checkbox#tab-10:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(10):after {
  height: 3px;
  background-color: #FDE900;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-tabs__checkbox#tab-10:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(10).rfb-tabs__item--light:after {
  bottom: -4px;
}
@media (min-width: 576px) {
  .rfb-tabs__checkbox#tab-10:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(10).rfb-tabs__item--light:after {
    bottom: calc(-5.2083333333vw + 26px);
  }
}
@media (min-width: 768px) {
  .rfb-tabs__checkbox#tab-10:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(10).rfb-tabs__item--light:after {
    bottom: -14px;
  }
}
.rfb-tabs__checkbox#tab-10:checked ~ .rfb-tabs__buttons .rfb-tabs__item:nth-child(10).rfb-tabs__item--dark:after {
  background-color: #000;
}
.rfb-tabs__content__tab {
  display: none;
}

.rfb-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.rfb-ellipsis--2 {
  -webkit-line-clamp: 2;
}
.rfb-ellipsis--3 {
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.rfb-border-b {
  border-bottom: 2px solid #000;
}

.rfb-col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.rfb-col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.rfb-col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.rfb-col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.rfb-col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.rfb-col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.rfb-col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.rfb-col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.rfb-col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.rfb-col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.rfb-col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.rfb-col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

@media (max-width: 576px) {
  .rfb-col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .rfb-col-xs-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .rfb-col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .rfb-col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .rfb-col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .rfb-col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .rfb-col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .rfb-col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .rfb-col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .rfb-col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .rfb-col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .rfb-col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .rfb-col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .rfb-col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
}
@media (min-width: 768px) {
  .rfb-w-sm-max-content {
    width: max-content;
  }

  .rfb-m-sm-auto
   {
    margin: 0 auto;
  }

  .rfb-col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .rfb-col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .rfb-col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .rfb-col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .rfb-col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .rfb-col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .rfb-col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .rfb-col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .rfb-col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .rfb-col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .rfb-col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .rfb-col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
}
@media (min-width: 992px) {
  .rfb-d-lg-none {
    display: none;
  }

  .rfb-d-md-block {
    display: block;
  }

  .rfb-col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .rfb-col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .rfb-col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .rfb-col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .rfb-col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .rfb-col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .rfb-col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .rfb-col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .rfb-col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .rfb-col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .rfb-col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .rfb-col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
}
@media (min-width: 1200px) {
  .rfb-d-lg-block {
    display: block;
  }

  .rfb-hidden-xl {
    display: none;
  }

  .rfb-col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
@media (max-width: 1200px) {
  .rfb-max-w-lg-100 {
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .rfb-mb-md-0 {
    margin-bottom: 0;
  }
  .rfb-mb-md-8 {
    margin-bottom: 8px;
  }
  .rfb-mb-md-16 {
    margin-bottom: 16px;
  }
  .rfb-mb-md-24 {
    margin-bottom: 24px;
  }
  .rfb-mb-md-32 {
    margin-bottom: 32px;
  }
  .rfb-mb-md-48 {
    margin-bottom: 48px;
  }
  .rfb-mb-md-56 {
    margin-bottom: 56px;
  }

  .rfb-mt-md-0 {
    margin-top: 0;
  }
  .rfb-mt-md-8 {
    margin-top: 8px;
  }
  .rfb-mt-md-24 {
    margin-top: 24px;
  }
  .rfb-mt-md-32 {
    margin-top: 32px;
  }
  .rfb-mt-md-40 {
    margin-top: 40px;
  }
  .rfb-mt-md-56 {
    margin-top: 56px;
  }
  .rfb-mt-md-64 {
    margin-top: 64px;
  }
  .rfb-mt-md-auto {
    margin-top: auto;
  }

  .rfb-w-md-50 {
    width: 50%;
  }
  .rfb-w-md-75 {
    width: 75%;
  }
  .rfb-w-md-100 {
    width: 100%;
  }

  .rfb-max-w-md-100 {
    max-width: 100%;
  }

  .rfb-hidden-md {
    display: none;
  }

  .rfb-p-md-30 {
    padding: 30px 0;
  }

  .rfb-p-md-0 {
    padding: 0;
  }

  .rfb-pb-md-50 {
    padding-bottom: 50px;
  }
  .rfb-pb-md-70 {
    padding-bottom: 70px;
  }

  .rfb-flex-md--justify-start {
    justify-content: flex-start;
  }
  .rfb-flex-md--items-start {
    align-items: flex-start;
  }
  .rfb-flex-md--wrap {
    flex-wrap: wrap;
  }
  .rfb-flex-md--justify-center {
    justify-content: center;
  }
  .rfb-flex-md--direction-column {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .rfb-mt-sm-0 {
    margin-top: 0;
  }
  .rfb-mt-sm-8 {
    margin-top: 8px;
  }
  .rfb-mt-sm-24 {
    margin-top: 24px;
  }
  .rfb-mt-sm-32 {
    margin-top: 32px;
  }
  .rfb-mt-sm-40 {
    margin-top: 40px;
  }
  .rfb-mt-sm-56 {
    margin-top: 56px;
  }
  .rfb-mt-sm-64 {
    margin-top: 64px;
  }
  .rfb-mt-sm-auto {
    margin-top: auto;
  }

  .rfb-ml-sm-auto {
    margin-left: auto;
  }

  .rfb-mr-sm-auto {
    margin-right: auto;
  }

  .rfb-sm-relative {
    position: relative;
  }

  .rfb-sm-w-100 {
    width: 100%;
  }

  .rfb-sm-r-auto {
    right: auto;
  }

  .rfb-sm-l-auto {
    left: auto;
  }

  .rfb-w-sm-100 {
    width: 100%;
  }

  .rfb-pl-sm-20 {
    padding-left: 20px;
  }

  .rfb-mb-sm {
    margin-bottom: 14px;
  }
}
@media (max-width: 768px) {
  .rfb-sm-relative {
    position: relative;
  }

  .rfb-column-sm-revert {
    flex-direction: column-reverse;
  }

  .rfb-flex-sm--wrap {
    flex-wrap: wrap;
  }
  .rfb-flex-sm--justify-start {
    justify-content: flex-start;
  }
  .rfb-flex-sm--justify-center {
    justify-content: center;
  }
  .rfb-flex-sm--align-items {
    align-items: flex-end;
  }
  .rfb-flex-sm--direction-column {
    flex-direction: column;
  }

  .rfb-d-sm-block {
    display: block;
  }
  .rfb-d-sm-none {
    display: none;
  }

  .rfb-w-sm-100 {
    width: 100%;
  }

  .rfb-max-w-50 {
    max-width: 100%;
  }
  .rfb-max-w-40 {
    max-width: 100%;
  }
  .rfb-max-w-65 {
    max-width: 100%;
  }
  .rfb-max-w-80 {
    max-width: 100%;
  }
  .rfb-max-w-60 {
    max-width: 100%;
  }
  .rfb-max-w-65 {
    max-width: 100%;
  }
  .rfb-max-w-70 {
    max-width: 100%;
  }

  .rfb-comp--padding {
    padding: 60px 0;
  }

  .rfb-p-40 {
    padding: 20px 0;
  }

  .rfb-mt-sm-16 {
    margin-top: 16px;
  }
  .rfb-mt-sm-30 {
    margin-top: 30px;
  }
  .rfb-mt-sm-0 {
    margin-top: 0;
  }

  .rfb-ml-sm-16 {
    margin-left: 16px;
  }

  .rfb-mr-sm-0 {
    margin-right: 0;
  }
  .rfb-mr-sm-16 {
    margin-right: 16px;
  }
  .rfb-mr-sm-10 {
    margin-right: 10px;
  }
  .rfb-mr-sm-20 {
    margin-right: 20px;
  }

  .rfb-mb-sm {
    margin-bottom: 14px;
  }
  .rfb-mb-sm-0 {
    margin-bottom: 0;
  }
  .rfb-mb-sm-8 {
    margin-bottom: 8px;
  }
  .rfb-mb-sm-20 {
    margin-bottom: 20px;
  }
  .rfb-mb-sm-16 {
    margin-bottom: 16px;
  }
  .rfb-mb-sm-24 {
    margin-bottom: 24px;
  }
  .rfb-mb-sm-32 {
    margin-bottom: 32px;
  }
  .rfb-mb-sm-40 {
    margin-bottom: 40px;
  }
  .rfb-mb-sm-48 {
    margin-bottom: 48px;
  }
  .rfb-mb-sm-56 {
    margin-bottom: 56px;
  }
  .rfb-mb-sm-64 {
    margin-bottom: 64px;
  }
  .rfb-mb-sm-72 {
    margin-bottom: 72px;
  }

  .rfb-pt-sm-0 {
    padding-top: 0px;
  }
  .rfb-pt-sm-56 {
    padding-top: 56px;
  }

  .rfb-pb-sm-24 {
    padding-bottom: 24px;
  }
  .rfb-pb-sm-72 {
    padding-bottom: 72px;
  }

  .rfb-pl-sm-0 {
    padding-left: 0;
  }
  .rfb-pl-sm-8 {
    padding-left: 8px;
  }
  .rfb-pl-sm-52 {
    padding-left: 52px;
  }
  .rfb-pl-sm-60 {
    padding-left: 60px;
  }
  .rfb-pl-sm-40 {
    padding-left: 40px;
  }
  .rfb-pl-sm-16 {
    padding-left: 16px;
  }
  .rfb-pl-sm-0 {
    padding-left: 0;
  }

  .rfb-p-sm-25 {
    padding: 25px;
  }

  .rfb-pr-sm-52 {
    padding-right: 52px;
  }
  .rfb-pr-sm-60 {
    padding-right: 60px;
  }
  .rfb-pr-sm-40 {
    padding-right: 40px;
  }
  .rfb-pr-sm-20 {
    padding-right: 20px;
  }
  .rfb-pr-sm-16 {
    padding-right: 16px;
  }
  .rfb-pr-sm-0 {
    padding-right: 0;
  }

  .rfb-pr-sm-lr-60 {
    padding: 0 60px;
  }

  .rfb-text-sm-center {
    text-align: center;
  }
  .rfb-text-sm-left {
    text-align: left;
  }

  .rfb-parag-small {
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .rfb-d-sm-none {
    display: none;
  }
  .rfb-d-sm-flex {
    display: flex;
  }

  .rfb-flex-xs {
    display: flex;
  }
  .rfb-flex-xs--items-center {
    align-items: center;
  }

  .rfb-block-xs {
    display: block;
  }

  .rfb-w-xs-100 {
    width: 100%;
  }

  .rfb-max-w-xs-100 {
    max-width: 100%;
  }

  .rfb-pl-xs-0 {
    padding-left: 0;
  }

  .rfb-mr-xs-0 {
    margin-right: 0;
  }

  .rfb-mb-xs-8 {
    margin-bottom: 8px;
  }
  .rfb-mb-xs-16 {
    margin-bottom: 16px;
  }
  .rfb-mb-xs-20 {
    margin-bottom: 20px;
  }
  .rfb-mb-xs-30 {
    margin-bottom: 30px;
  }
  .rfb-mb-xs-50 {
    margin-bottom: 50px;
  }

  .rfb-title-fourth {
    line-height: 1.36;
  }
}
.rfb-footer {
  padding-top: 60px;
  padding-bottom: 50px;
  font-size: 18px;
  color: #fff;
  line-height: 1.3333333333;
  background-color: #121212;
}
@media (min-width: 576px) {
  .rfb-footer {
    padding-top: calc(9.6153846154vw + 4.6153846154px);
  }
}
@media (min-width: 992px) {
  .rfb-footer {
    padding-top: 100px;
  }
}
@media (min-width: 576px) {
  .rfb-footer {
    padding-bottom: calc(9.1346153846vw - 2.6153846154px);
  }
}
@media (min-width: 992px) {
  .rfb-footer {
    padding-bottom: 88px;
  }
}
.rfb-footer__phone {
  list-style: none;
  line-height: 1.6666666667;
}
.rfb-footer__links-list {
  list-style: none;
}
.rfb-footer__submit {
  width: 64px;
  height: 64px;
  border: 1px solid #FFF400;
  cursor: pointer;
  background-color: #FFF400;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30px' height='30px' x='0px' y='0px' viewBox='0 0 507.36 507.36'%3E%3Cpath d='M491.36,0.08h-256v32H452.8L0,484.72l22.56,22.56l452.8-452.64v201.44h32v-240C507.36,7.243,500.197,0.08,491.36,0.08z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-footer__submit:hover {
  background-color: #fff;
}
.rfb-footer__submit:focus {
  outline: none;
}
.rfb-footer__input {
  width: 350px;
  height: 64px;
  padding: 10px 25px;
  font-size: 18px;
  color: #fff;
  border: 1px solid #292929;
  background-color: #292929;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  line-height: 2;
}
.rfb-footer__input:focus {
  outline: none;
  border-color: #FDE900;
}
.rfb-footer__input::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 2.1666666667;
  color: rgba(255, 255, 255, 0.7);
}
.rfb-footer__input:-ms-input-placeholder {
  font-size: 18px;
  line-height: 2.1666666667;
  color: rgba(255, 255, 255, 0.7);
}
.rfb-footer__input::placeholder {
  font-size: 18px;
  line-height: 2.1666666667;
  color: rgba(255, 255, 255, 0.7);
}
.rfb-footer__button {
  padding: 10px 20px;
}

.rfb-footer__subscribe input {
  width: 350px;
  height: 64px;
  padding: 10px 25px;
  font-size: 18px;
  color: #fff;
  border: 1px solid #292929;
  background-color: #292929;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  line-height: 2;
}
.rfb-footer__subscribe input:focus {
  outline: none;
  border-color: #FDE900;
}
.rfb-footer__subscribe input::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 2.1666666667;
  color: rgba(255, 255, 255, 0.7);
}
.rfb-footer__subscribe input:-ms-input-placeholder {
  font-size: 18px;
  line-height: 2.1666666667;
  color: rgba(255, 255, 255, 0.7);
}
.rfb-footer__subscribe input::placeholder {
  font-size: 18px;
  line-height: 2.1666666667;
  color: rgba(255, 255, 255, 0.7);
}
.rfb-footer__subscribe .msg-alert {
  position: absolute;
  right: 0;
  bottom: -40px;
}

.rfb-footer__subscribe button {
  width: 64px;
  height: 64px;
  border: 1px solid #FFF400;
  cursor: pointer;
  background-color: #FFF400;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30px' height='30px' x='0px' y='0px' viewBox='0 0 507.36 507.36'%3E%3Cpath d='M491.36,0.08h-256v32H452.8L0,484.72l22.56,22.56l452.8-452.64v201.44h32v-240C507.36,7.243,500.197,0.08,491.36,0.08z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-footer__subscribe button:hover {
  background-color: #fff;
}
.rfb-footer__subscribe button:focus {
  outline: none;
}

.rfb-header {
  z-index: 1;
}
.rfb-header-h {
  height: 35px;
}
.rfb-header-h img {
  height: 100%;
}
.rfb-header__ham {
  width: 24px;
  height: 18px;
  cursor: pointer;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  will-change: trasnform;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-header__ham__line {
  display: block;
  position: absolute;
  left: 0;
  height: 3px;
  width: 26px;
  opacity: 1;
  background-color: #000;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  will-change: trasnform;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-header__ham__line:first-child {
  top: 0;
}
.rfb-header__ham__line:nth-child(2), .rfb-header__ham__line:nth-child(3) {
  top: 9px;
  width: 20px;
}
.rfb-header__ham__line:nth-child(4) {
  top: 18px;
}
.rfb-header__nav__list {
  list-style: none;
}
.rfb-header__nav__item {
  position: relative;
  width: max-content;
  margin: 0 25px;
}
.rfb-header__nav__item:last-child {
  margin-right: 0;
}
.rfb-header__nav__item--lang {
  margin-left: 24px;
}
.rfb-header__nav__item--search {
  margin-left: 20px;
  cursor: pointer;
  z-index: 2;
}
.rfb-header__nav__item:not(.rfb-header__nav__item--lang):not(.rfb-header__nav__item--search) .rfb-header__nav__link:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 0;
  background-color: #2F2F2F;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-header__nav__item:not(.rfb-header__nav__item--lang):not(.rfb-header__nav__item--search) .rfb-header__nav__link.active:before, .rfb-header__nav__item:not(.rfb-header__nav__item--lang):not(.rfb-header__nav__item--search) .rfb-header__nav__link:hover:before {
  width: 100%;
}
.rfb-header__nav__link {
  position: relative;
  font-size: 22px;
  line-height: 1.2222222222;
  font-weight: 500;
  color: #2F2F2F;
  z-index: 1;
  -webkit-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .rfb-header__nav__link {
    font-size: calc(3.5874439462vw - 5.5515695067px);
  }
}
@media (min-width: 991px) {
  .rfb-header__nav__link {
    font-size: calc(-1200vw + 11922px);
  }
}
@media (min-width: 992px) {
  .rfb-header__nav__link {
    font-size: 18px;
  }
}
.rfb-header__nav__link--lang {
  color: rgba(0, 0, 0, 0.23);
}
.rfb-header__nav__link--lang:not(:last-child) {
  padding-right: 12px;
  margin-right: 12px;
  border-right: 1px solid rgba(0, 0, 0, 0.23);
}
.rfb-header__nav__link--lang--active, .rfb-header__nav__link--lang:hover {
  color: #2F2F2F;
}
.rfb-header__close-icon {
  left: 0;
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  will-change: trasnform;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-header__search-icon {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
  will-change: trasnform;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-header__form {
  position: absolute;
  right: -6px;
  top: 50%;
  width: 0;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  z-index: 1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  will-change: trasnform;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-header__input {
  height: 40px;
  width: 0;
  padding: 5px 0;
  font-size: 16px;
  opacity: 0;
  visibility: hidden;
  border: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-header__input:focus {
  outline: none;
}
.rfb-header--open-search .rfb-header__form {
  width: 260px;
  border-radius: 10px;
}
.rfb-header--open-search .rfb-header__input {
  width: 230px;
  padding: 5px 10px;
  opacity: 1;
  visibility: visible;
  border-radius: 10px;
  transition-delay: 0.3s;
}
.rfb-header--open-search .rfb-header__search-icon {
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  will-change: trasnform;
}
.rfb-header--open-search .rfb-header__close-icon {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
  will-change: trasnform;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

@media (max-width: 992px) {
  .rfb-menu-open {
    overflow: hidden;
    height: 100%;
  }
  .rfb-menu-open body {
    overflow: hidden;
    height: 100%;
  }
  .rfb-menu-open .rfb-header__nav {
    height: calc(100vh - 113px);
    padding: 32px 15px;
    -webkit-transition: height 0.3s ease-in-out;
    -moz-transition: height 0.3s ease-in-out;
    -o-transition: height 0.3s ease-in-out;
    transition: height 0.3s ease-in-out;
  }
  .rfb-menu-open .rfb-header__nav__item {
    text-align: left;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    transition-delay: 0.3s;
  }
  .rfb-menu-open .rfb-header__ham__line:first-child, .rfb-menu-open .rfb-header__ham__line:nth-child(4) {
    top: 8px;
    width: 0;
    left: 50%;
  }
  .rfb-menu-open .rfb-header__ham__line:nth-child(2) {
    width: 26px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    will-change: trasnform;
  }
  .rfb-menu-open .rfb-header__ham__line:nth-child(3) {
    width: 26px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    will-change: trasnform;
  }
  .rfb-menu-open .rfb-header__form {
    left: 0;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    transition-delay: 0.3s;
  }
  .rfb-menu-open .rfb-header__input {
    opacity: 1;
    visibility: visible;
  }

  .rfb-header__nav {
    height: 0;
    position: absolute;
    top: 113px;
    left: 0;
    width: 100%;
    background-color: #000;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    transition-delay: 0.5s;
  }
  .rfb-header__nav__list {
    display: block;
    height: auto;
  }
  .rfb-header__nav__link {
    color: #fff;
  }
  .rfb-header__nav__link--lang {
    color: rgba(255, 255, 255, 0.5);
  }
  .rfb-header__nav__link--lang:first-child {
    border-color: rgba(255, 255, 255, 0.5);
  }
  .rfb-header__nav__link--lang--active, .rfb-header__nav__link--lang:hover {
    color: #fff;
  }
  .rfb-header__nav__item {
    margin-left: 0;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  .rfb-header__nav__item:not(:last-child) {
    margin-bottom: 16px;
  }
  .rfb-header__nav__item--search svg path {
    fill: #fff;
  }
  .rfb-header__nav__item:not(.rfb-header__nav__item--lang):not(.rfb-header__nav__item--search):before {
    background-color: #fff;
  }
  .rfb-header__nav__item--lang, .rfb-header__nav__item--search {
    margin-top: 40px;
  }
  .rfb-header__form {
    position: relative;
    top: 0;
    width: 50%;
    left: 15px;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    will-change: trasnform;
  }
  .rfb-header__form--open {
    width: 80%;
  }
  .rfb-header__input {
    width: 100%;
    padding: 5px 10px;
    padding-right: 35px;
    opacity: 0;
    visibility: hidden;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 25.314 25.314'%3E%3Cpath fill='%23000' d='M18.577,16.342a10.322,10.322,0,1,0-2.237,2.237l6.737,6.737,2.237-2.237-6.737-6.737Zm-8.293,1.064a7.12,7.12,0,1,1,7.12-7.12,7.127,7.127,0,0,1-7.12,7.12Z' transform='translate(0 -0.002)'/%3E%3C/svg%3E");
    background-position: center right 10px;
    background-repeat: no-repeat;
  }
  .rfb-header__search-icon, .rfb-header__close-icon {
    display: none;
  }
  .rfb-header--open-search .rfb-header__form {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .rfb-header__nav {
    top: 70px;
  }
  .rfb-header__logo img {
    height: 30px;
  }

  .rfb-menu-open .rfb-header__nav {
    height: calc(100vh - 70px);
  }
  .rfb-footer__input-holder {
    width: 100%;
  }
  .rfb-footer__input-holder .rfb-footer__input {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .rfb-header .rfb-header__form {
    width: 100%;
  }
}
.rfb-cards__item {
  width: calc(100% / 3 - 5px);
  padding-top: calc(100% / 3 - 5px);
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin-bottom: 7px;
}
.rfb-cards__item:hover .rfb-cards__item-title {
  color: #FDE900;
  border-color: #FDE900;
}
.rfb-cards__item-btn {
  padding-left: 19px;
  padding-bottom: 26px;
  padding-right: 19px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
@media (min-width: 576px) {
  .rfb-cards__item-btn {
    padding-left: calc(2.6041666667vw + 4px);
  }
}
@media (min-width: 768px) {
  .rfb-cards__item-btn {
    padding-left: calc(2.6785714286vw + 3.4285714286px);
  }
}
@media (min-width: 992px) {
  .rfb-cards__item-btn {
    padding-left: 30px;
  }
}
@media (min-width: 576px) {
  .rfb-cards__item-btn {
    padding-bottom: calc(3.125vw + 8px);
  }
}
@media (min-width: 768px) {
  .rfb-cards__item-btn {
    padding-bottom: calc(3.5714285714vw + 4.5714285714px);
  }
}
@media (min-width: 992px) {
  .rfb-cards__item-btn {
    padding-bottom: 40px;
  }
}
@media (min-width: 576px) {
  .rfb-cards__item-btn {
    padding-right: calc(2.6041666667vw + 4px);
  }
}
@media (min-width: 768px) {
  .rfb-cards__item-btn {
    padding-right: calc(2.6785714286vw + 3.4285714286px);
  }
}
@media (min-width: 992px) {
  .rfb-cards__item-btn {
    padding-right: 30px;
  }
}
.rfb-cards__item-btn:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60%;
  background-color: transparent;
  background-image: linear-gradient(to bottom, transparent, #121212);
}

.rfb-cards__item-title {
  border-top: 2px solid #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.rfb-cards__item-date {
  font-size: 13px;
}

.rfb-cards__item:not(:nth-child(3n)) {
  margin-right: 5px;
}

.rfb-cards__title {
  font-size: 26px;
}
@media (min-width: 576px) {
  .rfb-cards__title {
    font-size: calc(4.1666666667vw + 2px);
  }
}
@media (min-width: 768px) {
  .rfb-cards__title {
    font-size: calc(3.5714285714vw + 6.5714285714px);
  }
}
@media (min-width: 992px) {
  .rfb-cards__title {
    font-size: 42px;
  }
}

@media (max-width: 992px) {
  .rfb-cards__item {
    width: calc(100% / 2 - 5px);
    padding-top: calc(100% / 2 - 5px);
  }
  .rfb-cards__item:not(:nth-child(3n)) {
    margin-right: 0;
  }
  .rfb-cards__item:nth-child(2n) {
    width: calc(100% / 2);
  }
  .rfb-cards__item:not(:nth-child(2n)) {
    margin-right: 5px;
  }

  .rfb-cards__button {
    order: 3;
  }
}
@media (max-width: 767px) {
  .rfb-cards__item {
    width: 100%;
    padding-top: 100%;
    margin-bottom: 7px;
    margin-right: 0;
  }
  .rfb-cards__item:nth-child(2n) {
    width: 100%;
  }
  .rfb-cards__item:not(:nth-child(3n)), .rfb-cards__item:not(:nth-child(2n)) {
    margin-right: 0;
  }
}
.rfb-cards-ao__title {
  min-height: 68px;
  font-size: 18px;
  line-height: 1.89;
}

.rfb-cards-ao__picture {
  height: 90px;
}

.rfb-cards-ao__img {
  max-height: 100%;
}

.rfb-cards-ao__parag {
  font-size: 15px;
  line-height: 1.73;
}

@media (max-width: 768px) {
  .rfb-cards-ao__title {
    min-height: auto;
  }
}
.rfb-fti {
  min-height: 350px;
  background-color: #171717;
}
.rfb-fti--large {
  padding: 140px 0;
}
.rfb-fti__picture {
  top: 0;
  right: 0;
  bottom: 0;
  width: 47%;
}
.rfb-fti__title {
  font-size: 28px;
  line-height: 1.0952380952;
}
@media (min-width: 576) {
  .rfb-fti__title {
    font-size: calc(3.125vw + 10px);
  }
}
@media (min-width: 768px) {
  .rfb-fti__title {
    font-size: calc(3.5714285714vw + 6.5714285714px);
  }
}
@media (min-width: 992px) {
  .rfb-fti__title {
    font-size: 42px;
  }
}
.rfb-fti-link {
  font-size: 18px;
  line-height: 1.3333333333;
  color: #FFF400;
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
.rfb-fti-link:hover {
  color: #d6d281;
}

@media (max-width: 768px) {
  .rfb-fti--large {
    padding: 0;
  }
  .rfb-fti__picture img {
    height: 330px;
  }
  .rfb-fti__wrapper {
    padding: 50px 0;
  }
}
.rfb-hero--padding {
  padding-top: 56px;
  padding-bottom: 56px;
}
@media (min-width: 576px) {
  .rfb-hero--padding {
    padding-top: calc(12.7403846154vw - 17.3846153846px);
  }
}
@media (min-width: 992px) {
  .rfb-hero--padding {
    padding-top: 109px;
  }
}
@media (min-width: 576px) {
  .rfb-hero--padding {
    padding-bottom: calc(12.0192307692vw - 13.2307692308px);
  }
}
@media (min-width: 992px) {
  .rfb-hero--padding {
    padding-bottom: 106px;
  }
}

.rfb-hero__picture {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: -1;
}

.rfb-hero--picture-left {
  left: 0;
}

.rfb-hero--picture-right {
  right: 0;
}

.rfb-hero__holder {
  padding: 23px 25px;
}

.rfb-hero__description {
  font-size: 14px;
  line-height: 1.7272727273;
}
@media (min-width: 576px) {
  .rfb-hero__description {
    font-size: calc(1.9230769231vw + 2.9230769231px);
  }
}
@media (min-width: 992px) {
  .rfb-hero__description {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .rfb-hero__holder {
    padding: 0;
  }
}
.rfb-hero--padding {
  padding-top: 56px;
  padding-bottom: 56px;
}
@media (min-width: 576px) {
  .rfb-hero--padding {
    padding-top: calc(12.7403846154vw - 17.3846153846px);
  }
}
@media (min-width: 992px) {
  .rfb-hero--padding {
    padding-top: 109px;
  }
}
@media (min-width: 576px) {
  .rfb-hero--padding {
    padding-bottom: calc(12.0192307692vw - 13.2307692308px);
  }
}
@media (min-width: 992px) {
  .rfb-hero--padding {
    padding-bottom: 106px;
  }
}

.rfb-hero__picture {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: -1;
}

.rfb-hero--picture-left {
  left: 0;
}

.rfb-hero--picture-right {
  right: 0;
}

.rfb-hero__holder {
  padding: 23px 25px;
}

@media (max-width: 767px) {
  .rfb-hero__holder {
    padding: 0;
  }
}
.rfb-expandable__item {
  padding-right: 12px;
  border-bottom: 1px solid #DDDDDD;
  user-select: none;
}
.rfb-expandable__item:first-child {
  border-top: none;
}
.rfb-expandable__item:last-child {
  border-bottom: none;
}
.rfb-expandable__item__head {
  cursor: pointer;
  -webkit-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}
.rfb-expandable__item__head::-webkit-details-marker {
  display: none;
}
.rfb-expandable__item__head:focus {
  outline: none;
}
.rfb-expandable__item__head:hover {
  color: #FDE900;
}
.rfb-expandable__item__head__icon {
  flex: 0 0 70px;
}
.rfb-expandable__item__head__title {
  font-size: 16px;
  line-height: 0.9411764706;
}
@media (min-width: 576px) {
  .rfb-expandable__item__head__title {
    font-size: calc(4.3269230769vw - 8.9230769231px);
  }
}
@media (min-width: 992px) {
  .rfb-expandable__item__head__title {
    font-size: 34px;
  }
}
.rfb-expandable__item__icon {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  will-change: trasnform;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.rfb-expandable__item__icon__arrow {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.rfb-expandable__item:hover .rfb-expandable__item__icon__arrow {
  fill: #FDE900;
}
.rfb-expandable-tabs__item {
  margin: 0;
  font-size: 20px;
  line-height: 1.3235294118;
}
@media (min-width: 576px) {
  .rfb-expandable-tabs__item {
    font-size: calc(3.3653846154vw + 0.6153846154px);
  }
}
@media (min-width: 992px) {
  .rfb-expandable-tabs__item {
    font-size: 34px;
  }
}
.rfb-expandable-tabs__item:after {
  height: 3px;
  border-radius: 100px;
}
.rfb-expandable-title {
  margin-bottom: 60px;
  font-size: 25px;
  line-height: 1.5238095238;
}
.rfb-expandable-title-small {
  font-size: 20px;
}
@media (min-width: 768px) {
  .rfb-expandable-title {
    margin-bottom: calc(22.3214285714vw - 111.4285714286px);
  }
}
@media (min-width: 992px) {
  .rfb-expandable-title {
    margin-bottom: 110px;
  }
}
@media (min-width: 576px) {
  .rfb-expandable-title {
    font-size: calc(4.0865384615vw + 1.4615384615px);
  }
}
@media (min-width: 992px) {
  .rfb-expandable-title {
    font-size: 42px;
  }
  .rfb-expandable-title-small {
    font-size: 35px;
  }
}
.rfb-expandable [open] .rfb-expandable__item__title {
  padding-bottom: 24px;
}
.rfb-expandable [open] .rfb-expandable__item__icon svg {
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  transform: rotateX(180deg);
  will-change: trasnform;
}

@media (max-width: 768px) {
  .rfb-expandable__item__head__icon {
    flex: 0 0 50px;
  }
  .rfb-expandable-tabs__item {
    padding: 0 15px 10px 15px;
  }
  .rfb-expandable-tabs__item:first-child {
    padding-left: 0;
  }
}
.rfb-contact .rfb-form__item {
  outline: none;
  border: none;
  border-bottom: 2px solid #C9C9C9;
  font-size: 19px;
  font-family: "NHaasGroteskTXPro";
  font-weight: 500;
}
.rfb-contact .rfb-form__item--textarea {
  resize: none;
}
.rfb-contact .rfb-form__item--checkbox {
  position: relative;
  cursor: pointer;
  margin-top: 3px;
}
.rfb-contact .rfb-form__item--checkbox:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid #000;
  background-color: #fff;
}
.rfb-contact .rfb-form__item--checkbox:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
.rfb-contact .rfb-form__item--submit {
  height: 66px;
  cursor: pointer;
  border: 1px solid #FDE900;
  background-position: left 15px center;
  background-repeat: no-repeat;
}
.rfb-contact .rfb-form__item--submit.loading {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg' stroke='%23fff'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)' stroke-width='2'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='1s' repeatCount='indefinite'/%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.rfb-contact .rfb-form__item::placeholder {
  color: #fff;
  opacity: 1;
  font-size: 19px;
  font-weight: 500;
}
.rfb-contact .rfb-form__subscribe {
  font-size: 14px;
  line-height: 1.4285714286;
}
.rfb-contact .rfb-form__subscribe-label {
  letter-spacing: 0.3;
}
.rfb-contact .rfb-form__contact {
  background-color: #F3F3F3;
}
.rfb-contact .rfb-form__contact-text {
  font-size: 16px;
  line-height: 1.5263157895;
}
@media (min-width: 576px) {
  .rfb-contact .rfb-form__contact-text {
    font-size: calc(0.7211538462vw + 11.8461538462px);
  }
}
@media (min-width: 992px) {
  .rfb-contact .rfb-form__contact-text {
    font-size: 19px;
  }
}
.rfb-contact__icon {
  width: 24px;
  margin-top: 2px;
}
@media (min-width: 576px) {
  .rfb-contact__icon {
    width: calc(3.8461538462vw + 1.8461538462px);
  }
}
@media (min-width: 992px) {
  .rfb-contact__icon {
    width: 40px;
  }
}
@media (min-width: 576px) {
  .rfb-contact__icon {
    margin-top: calc(0.9615384615vw - 3.5384615385px);
  }
}
@media (min-width: 992px) {
  .rfb-contact__icon {
    margin-top: 6px;
  }
}
.rfb-claims {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.rfb-taif-window-height-slider {
  height: calc( 100vh - 137px );
}
.rfb-taif-window-padding {
  min-height: 500px;
  padding-top: 60px;
}
@media (min-width: 768px) {
  .rfb-taif-window-padding {
    min-height: calc(142.8571428571vw - 597.1428571429px);
  }
}
@media (min-width: 992px) {
  .rfb-taif-window-padding {
    min-height: 820px;
  }
}
@media (min-width: 768px) {
  .rfb-taif-window-padding {
    padding-top: calc(34.375vw - 204px);
  }
}
@media (min-width: 992px) {
  .rfb-taif-window-padding {
    padding-top: 137px;
  }
}
.rfb-taif-slider--min-height {
  min-height: 500px;
}
@media (min-width: 768px) {
  .rfb-taif-slider--min-height {
    min-height: calc(107.1428571429vw - 322.8571428571px);
  }
}
@media (min-width: 992px) {
  .rfb-taif-slider--min-height {
    min-height: 740px;
  }
}
.rfb-taif-slider-title {
  font-size: 22px;
  line-height: 1.2608695652;
}
@media (min-width: 576) {
  .rfb-taif-slider-title {
    font-size: calc(5.2083333333vw - 8px);
  }
}
@media (min-width: 768px) {
  .rfb-taif-slider-title {
    font-size: calc(6.25vw - 16px);
  }
}
@media (min-width: 992px) {
  .rfb-taif-slider-title {
    font-size: 46px;
  }
}
.rfb-taif-button-explore {
  border: 1px solid transparent;
}
.rfb-taif-button-continue {
  cursor: pointer;
  height: 80px;
  font-size: 18px;
  line-height: 2.6363636364;
}
@media (min-width: 576) {
  .rfb-taif-button-continue {
    font-size: calc(0.9615384615vw + 12.4615384615px);
  }
}
@media (min-width: 992px) {
  .rfb-taif-button-continue {
    font-size: 22px;
  }
}
.rfb-taif-button-continue:hover svg path {
  fill: #FFF400;
}
.rfb-taif-button-continue:focus {
  outline: none;
}
.rfb-taif-button-submit {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-taif-button-submit:hover path {
  fill: #FDE900;
}
.rfb-taif-pagination-home-slider.swiper-pagination-bullets {
  bottom: 280px;
}
@media (min-width: 768px) {
  .rfb-taif-pagination-home-slider.swiper-pagination-bullets {
    bottom: calc(-12900vw + 99352px);
  }
}
@media (min-width: 770px) {
  .rfb-taif-pagination-home-slider.swiper-pagination-bullets {
    bottom: 22px;
  }
}
@media (min-width: 992px) {
  .rfb-taif-pagination-home-slider.swiper-pagination-bullets {
    bottom: 22px;
  }
}
.rfb-taif-pagination-home-slider.swiper-pagination-bullets .swiper-pagination-bullet::after {
  content: " ";
  position: absolute;
  top: -10px;
  left: 0;
  height: 16px;
  width: 100%;
}
.rfb-taif .swiper-pagination-bullet {
  position: relative;
  width: 27px;
  height: 1px;
  border-radius: 0;
  background-color: #fff;
  opacity: 0.7;
  outline: none;
}
.rfb-taif .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 40px;
  height: 3px;
  opacity: 1;
}
.rfb-taif-absolute-box {
  background-color: #fff;
  z-index: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-taif-absolute-box-title {
  font-size: 20px;
  line-height: 1.8125;
  color: #121212;
}
@media (min-width: 576) {
  .rfb-taif-absolute-box-title {
    font-size: calc(2.0833333333vw + 8px);
  }
}
@media (min-width: 768px) {
  .rfb-taif-absolute-box-title {
    font-size: calc(3.5714285714vw - 3.4285714286px);
  }
}
@media (min-width: 992px) {
  .rfb-taif-absolute-box-title {
    font-size: 32px;
  }
}
.rfb-taif-absolute-box-subtitle {
  font-size: 14px;
  color: #B8B8B8;
}
@media (min-width: 576) {
  .rfb-taif-absolute-box-subtitle {
    font-size: calc(0.4807692308vw + 11.2307692308px);
  }
}
@media (min-width: 992px) {
  .rfb-taif-absolute-box-subtitle {
    font-size: 16px;
  }
}
.rfb-taif-picture::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #000, transparent);
  opacity: 0.5;
}
.rfb-taif-label {
  font-size: 16px;
  cursor: pointer;
  color: #8F8F8F;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (min-width: 576) {
  .rfb-taif-label {
    font-size: calc(0.9615384615vw + 10.4615384615px);
  }
}
@media (min-width: 992px) {
  .rfb-taif-label {
    font-size: 20px;
  }
}
.rfb-taif-label:hover {
  color: #000;
}
.rfb-taif-label:hover::before {
  border: 1px solid #000;
}
.rfb-taif-label:before {
  content: "";
  display: inline-block;
  vertical-align: text-top;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  border: 1px solid #929292;
  border-radius: 2px;
  background-color: #fff;
}
.rfb-taif-option-input {
  opacity: 0;
}
.rfb-taif-option-input:checked + label:before {
  background-color: #000;
  border: 1px solid #000;
}
.rfb-taif-option-input:checked + label {
  color: #000;
}
.rfb-taif-option-input:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='15px' height='15px' viewBox='0 0 78.369 78.369' style='enable-background:new 0 0 78.369 78.369;' xml:space='preserve' fill='%23fff'%3E%3Cpath d='M78.049,19.015L29.458,67.606c-0.428,0.428-1.121,0.428-1.548,0L0.32,40.015c-0.427-0.426-0.427-1.119,0-1.547l6.704-6.704 c0.428-0.427,1.121-0.427,1.548,0l20.113,20.112l41.113-41.113c0.429-0.427,1.12-0.427,1.548,0l6.703,6.704 C78.477,17.894,78.477,18.586,78.049,19.015z'/%3E%3C/svg%3E");
  width: 14px;
  height: 14px;
  background-position: center;
  background-size: contain;
}
.rfb-taif-price {
  border: 0;
  font-size: 20px;
  line-height: 1.875;
  color: #141414;
}
@media (min-width: 576) {
  .rfb-taif-price {
    font-size: calc(0.9615384615vw + 14.4615384615px);
  }
}
@media (min-width: 992px) {
  .rfb-taif-price {
    font-size: 24px;
  }
}
.rfb-taif-price:focus {
  outline: none;
}
.rfb-taif-price-symbol {
  font-size: 20px;
  line-height: 1.875;
  color: #B8B8B8;
}
@media (min-width: 576) {
  .rfb-taif-price-symbol {
    font-size: calc(0.9615384615vw + 14.4615384615px);
  }
}
@media (min-width: 992px) {
  .rfb-taif-price-symbol {
    font-size: 24px;
  }
}
.rfb-taif-price::-moz-placeholder, .rfb-taif-price::placeholder {
  font-size: 18px;
  line-height: 2.0454545455;
  font-weight: 500;
  color: #B8B8B8;
  opacity: 1;
}
@media (min-width: 576) {
  .rfb-taif-price::-moz-placeholder, .rfb-taif-price::placeholder {
    font-size: calc(0.9615384615vw + 12.4615384615px);
  }
}
@media (min-width: 992px) {
  .rfb-taif-price::-moz-placeholder, .rfb-taif-price::placeholder {
    font-size: 22px;
  }
}

@media (max-width: 992px) {
  .rfb-taif-window-height-slider {
    height: calc( 100vh - 117px );
  }
}
@media (max-width: 768px) {
  .rfb-taif-window-height-slider, .rfb-taif__swiper-slide {
    height: auto;
  }
  .rfb-taif__slider--responsive {
    margin-bottom: 350px;
  }
  .rfb-taif-box {
    height: auto;
    margin-bottom: -250px;
  }
  .rfb-taif-box-border {
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
  .rfb-taif__box-responsive {
    -webkit-transform: translateY(-250px);
    -moz-transform: translateY(-250px);
    -ms-transform: translateY(-250px);
    -o-transform: translateY(-250px);
    transform: translateY(-250px);
    will-change: trasnform;
    padding-top: 0;
    z-index: 1;
  }
  .rfb-taif-flex-sm--align-start {
    align-items: flex-start;
  }
}
.rfb-taic {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}
.rfb-taic:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.4);
  z-index: -1;
}
.rfb-taic__statistics:last-child {
  margin-right: 0;
}
.rfb-taic__title {
  margin-bottom: 40px;
  line-height: 1.3725490196;
}
@media (min-width: 576px) {
  .rfb-taic__title {
    margin-bottom: calc(5.2083333333vw + 10px);
  }
}
@media (min-width: 768) {
  .rfb-taic__title {
    margin-bottom: calc(16.9642857143vw - 80.2857142857px);
  }
}
@media (min-width: 992px) {
  .rfb-taic__title {
    margin-bottom: calc(10.5769230769vw - 16.9230769231px);
  }
}
@media (min-width: 1200px) {
  .rfb-taic__title {
    margin-bottom: 110px;
  }
}
.rfb-taic__number {
  font-size: 34px;
  line-height: 1.3783783784;
}
@media (min-width: 576px) {
  .rfb-taic__number {
    font-size: calc(5.2083333333vw + 4px);
  }
}
@media (min-width: 768) {
  .rfb-taic__number {
    font-size: calc(5.3571428571vw + 2.8571428571px);
  }
}
@media (min-width: 992px) {
  .rfb-taic__number {
    font-size: calc(6.7307692308vw - 10.7692307692px);
  }
}
@media (min-width: 1200px) {
  .rfb-taic__number {
    font-size: 70px;
  }
}
.rfb-taic__label {
  margin-right: 0;
  font-size: 16px;
  line-height: 1.3571428571;
}
@media (min-width: 767px) {
  .rfb-taic__label {
    margin-right: calc(5000vw - 38350px);
  }
}
@media (min-width: 768px) {
  .rfb-taic__label {
    margin-right: calc(6.25vw + 2px);
  }
}
@media (min-width: 992px) {
  .rfb-taic__label {
    margin-right: calc(7.6923076923vw - 12.3076923077px);
  }
}
@media (min-width: 1200px) {
  .rfb-taic__label {
    margin-right: 80px;
  }
}
@media (min-width: 768) {
  .rfb-taic__label {
    font-size: calc(2.6785714286vw - 4.5714285714px);
  }
}
@media (min-width: 992px) {
  .rfb-taic__label {
    font-size: calc(2.8846153846vw - 6.6153846154px);
  }
}
@media (min-width: 1200px) {
  .rfb-taic__label {
    font-size: 28px;
  }
}

.rfb-map__wrapper {
  width: 100%;
  height: 530px;
}
.rfb-map__info-content {
  font-family: "NHaasGroteskTXPro";
  color: #000;
}

.gm-style-iw {
  position: absolute;
}

@font-face {
  font-family: "NHaasGroteskTXPro";
  font-display: fallback;
  font-weight: 400;
  src: url("./fonts/NeueHaasGroteskTextPro-55Roman.woff") format("woff"), url("./fonts/NeueHaasGroteskTextPro-55Roman.woff") format("woff");
}
@font-face {
  font-family: "NHaasGroteskTXPro";
  font-display: fallback;
  font-weight: 500;
  src: url("./fonts/NeueHaasGroteskTextPro-65Medium.woff") format("woff"), url("./fonts/NeueHaasGroteskTextPro-65Medium.woff") format("woff");
}
@font-face {
  font-family: "NHaasGroteskTXPro";
  font-display: fallback;
  font-weight: 700;
  src: url("./fonts/NeueHaasGroteskTextPro-75Bold.woff") format("woff"), url("./fonts/NeueHaasGroteskTextPro-75Bold.woff") format("woff");
}
/* Paddings */
.rfb-news-single {
  padding-bottom: 30px;
}
.rfb-news-single-border-b {
  border-bottom: 1px solid #EBEBEB;
}
.rfb-news-single__social__icon {
  color: #fff;
}
.rfb-news-single__social__icon__img {
  max-width: 30px;
  max-height: 30px;
}
.rfb-news-single__social__icon__img {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.rfb-news-single__social__icon:hover .rfb-news-single__social__icon__img {
  -webkit-transform: translateX(6px);
  -moz-transform: translateX(6px);
  -ms-transform: translateX(6px);
  -o-transform: translateX(6px);
  transform: translateX(6px);
  will-change: trasnform;
}
.rfb-news-single__social__icon:hover .rfb-news-single__social--text {
  -webkit-transform: translateX(-6px);
  -moz-transform: translateX(-6px);
  -ms-transform: translateX(-6px);
  -o-transform: translateX(-6px);
  transform: translateX(-6px);
  will-change: trasnform;
}
.rfb-news-single__social--text {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0.8;
}
.rfb-news-single__social-title {
  color: #A9ABAE;
}
.rfb-news-single__content__img {
  max-height: 400px;
  max-width: 100%;
  object-fit: contain;
}
.rfb-news-single__content {
  text-align: justify;
}
.rfb-news-single__content p {
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
  display: inline-block;
  font-size: 15px;
  line-height: 1.9583333333;
  white-space: pre-wrap;
}
.rfb-news-single__content b {
  font-weight: 700;
}
@media (min-width: 768px) {
  .rfb-news-single__content p {
    font-size: calc(1.7857142857vw + 1.2857142857px);
  }
}
@media (min-width: 992px) {
  .rfb-news-single__content p {
    font-size: calc(0.8223684211vw + 10.8421052632px);
  }
}
@media (min-width: 1600px) {
  .rfb-news-single__content p {
    font-size: 24px;
  }
}
.rfb-news-single__content p:first-child {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.8076923077;
}
@media (min-width: 768px) {
  .rfb-news-single__content p:first-child {
    font-size: calc(1.7857142857vw + 2.2857142857px);
  }
}
@media (min-width: 992px) {
  .rfb-news-single__content p:first-child {
    font-size: calc(0.9868421053vw + 10.2105263158px);
  }
}
@media (min-width: 1600px) {
  .rfb-news-single__content p:first-child {
    font-size: 26px;
  }
}
.rfb-news-single__content p:last-child {
  padding-bottom: 0;
}
.rfb-news-single-paragraph, .rfb-news-single__social-title, .rfb-news-single__social--text {
  letter-spacing: 0;
}
.rfb-news-single-paragraph time {
  font-weight: 500;
  text-transform: capitalize;
}
.rfb-news-single-container-pr {
  padding-right: 220px;
}
.rfb-news-single-title {
  width: 60%;
  font-size: 22px;
  line-height: 1.2380952381;
}
@media (min-width: 576px) {
  .rfb-news-single-title {
    font-size: calc(4.8076923077vw - 5.6923076923px);
  }
}
@media (min-width: 992px) {
  .rfb-news-single-title {
    font-size: 42px;
  }
}

@media (max-width: 1200px) {
  .rfb-news-single-container-pr {
    padding-right: 100px;
  }
}
@media (max-width: 992px) {
  .rfb-news-single-container-pr {
    padding-right: 15px;
  }
  .rfb-news-single-title {
    width: 90%;
  }
}
@media (max-width: 768px) {
  .rfb-news-single__social__icon__img {
    max-width: 20px;
    max-height: 20px;
  }
  .rfb-news-single__content {
    padding-bottom: 50px;
  }
  .rfb-news-single__content p {
    margin-bottom: 15px;
  }
}
@media (max-width: 576px) {
  .rfb-news-single-title {
    width: 100%;
  }
  .rfb-news-single-paragraph {
    padding-bottom: 10px;
  }
  .rfb-news-single-paragraph, .rfb-news-single__social {
    justify-content: flex-start;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .rfb-news-single-date {
    padding-bottom: 0;
    margin-bottom: 15px;
  }
}
.rfb-partners {
  color: #fff;
  background-color: #121212;
  border-bottom: 1px solid #313131;
  border-top: 1px solid #313131;
}
.rfb-partners__list {
  margin: 0 -20px;
}
.rfb-partners__list-item {
  padding: 0 20px;
  opacity: 0.6;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-partners__list-item:hover {
  opacity: 1;
}
.rfb-partners__list-img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  max-width: 140px;
}

@media (max-width: 768px) and (min-width: 576px) {
  .rfb-partners__list-item {
    width: calc(100% / 2);
  }
}
.rfb-slider-cards__box {
  padding: 30px;
  border: 1px solid #1a1a1a;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.rfb-slider-cards__box:hover {
  border-color: #FDE900;
}
.rfb-slider-cards__box__title {
  font-size: 20px;
  line-height: 1.3333333333;
}
@media (min-width: 576px) {
  .rfb-slider-cards__box__title {
    font-size: calc(2.0833333333vw + 8px);
  }
}
@media (min-width: 768px) {
  .rfb-slider-cards__box__title {
    font-size: 24px;
  }
}
.rfb-slider-cards__box__more {
  font-size: 18px;
  line-height: 1.2222222222;
}
.rfb-slider-cards--swiper-container {
  padding-bottom: 80px;
}
@media (min-width: 576px) {
  .rfb-slider-cards--swiper-container {
    padding-bottom: calc(20.8333333333vw - 40px);
  }
}
@media (min-width: 768px) {
  .rfb-slider-cards--swiper-container {
    padding-bottom: 120px;
  }
}
.rfb-slider-cards--swiper-button-next, .rfb-slider-cards--swiper-button-prev {
  top: unset;
  bottom: 0;
}
.rfb-slider-cards--swiper-button-next:after, .rfb-slider-cards--swiper-button-prev:after {
  display: none;
}
.rfb-slider-cards--swiper-button-next:focus, .rfb-slider-cards--swiper-button-prev:focus {
  outline: none;
}
.rfb-slider-cards--swiper-button-next {
  left: 63px;
}
.rfb-slider-cards__picture {
  height: 54px;
}

.rfb-taib {
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}
.rfb-taib__title {
  font-size: 28px;
  line-height: 1.2156862745;
}
@media (min-width: 576) {
  .rfb-taib__title {
    font-size: calc(5.2083333333vw - 2px);
  }
}
@media (min-width: 768px) {
  .rfb-taib__title {
    font-size: calc(5.8035714286vw - 6.5714285714px);
  }
}
@media (min-width: 992px) {
  .rfb-taib__title {
    font-size: 51px;
  }
}
.rfb-taib__parag {
  font-size: 20px;
  line-height: 1.875;
}
@media (min-width: 576) {
  .rfb-taib__parag {
    font-size: calc(0.9615384615vw + 14.4615384615px);
  }
}
@media (min-width: 992px) {
  .rfb-taib__parag {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .rfb-taib {
    background-position: center;
  }
  .swiper-pagination-home-slider {
    position: absolute;
    height: 30px;
    top: calc(50% - 200px);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.rfb-taib-slider__holder {
  padding: 20px;
}
@media (min-width: 576) {
  .rfb-taib-slider__holder {
    padding: calc(10.4166666667vw - 40px);
  }
}
@media (min-width: 768px) {
  .rfb-taib-slider__holder {
    padding: calc(8.9285714286vw - 28.5714285714px);
  }
}
@media (min-width: 992px) {
  .rfb-taib-slider__holder {
    padding: 60px;
  }
}
.rfb-taib-slider__parag {
  line-height: 1.7272727273;
}
.rfb-taib-slider__parag p {
  margin-bottom: 20px;
}
@media (min-width: 576) {
  .rfb-taib-slider__parag p {
    margin-bottom: calc(4.1666666667vw - 4px);
  }
}
@media (min-width: 768px) {
  .rfb-taib-slider__parag p {
    margin-bottom: calc(3.125vw + 4px);
  }
}
@media (min-width: 992px) {
  .rfb-taib-slider__parag p {
    margin-bottom: 35px;
  }
}
.rfb-taib-slider__picture {
  z-index: -1;
}
.rfb-taib-slider .swiper-pagination-bullet {
  height: 12px;
  width: 12px;
  background-color: #262626;
}
.rfb-taib-slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #262626;
}

.rfb-404__title {
  font-size: 39px;
  line-height: 1.3114754098;
}
@media (min-width: 576px) {
  .rfb-404__title {
    font-size: calc(5.2083333333vw + 9px);
  }
}
@media (min-width: 768px) {
  .rfb-404__title {
    font-size: calc(5.3571428571vw + 7.8571428571px);
  }
}
@media (min-width: 992px) {
  .rfb-404__title {
    font-size: 61px;
  }
}
.rfb-404__message {
  font-size: 22px;
  line-height: 1.1764705882;
}
@media (min-width: 576px) {
  .rfb-404__message {
    font-size: calc(2.6041666667vw + 7px);
  }
}
@media (min-width: 768px) {
  .rfb-404__message {
    font-size: calc(3.125vw + 3px);
  }
}
@media (min-width: 992px) {
  .rfb-404__message {
    font-size: 34px;
  }
}

.rfb-div-hidden {
  position: absolute;
  bottom: 0;
  opacity: 0;
  visibility: 0;
  z-index: -1;
}

.rfb-taib-slider .swiper-pagination-bullet.swiper-pagination-bullet-active,.rfb-taib-slider .swiper-pagination-bullet { 
  background-color: #fff;
}
.grecaptcha-badge { 
  right: auto!important;
  display: none!important;
}
/*# sourceMappingURL=style.min.css.map */
